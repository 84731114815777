import consumer_trains_order_getTrainsOrderDetail
    from '@/lib/data-service/haolv-default/consumer_trains_order_getTrainsOrderDetail'
import consumer_trains_order_getTrainsOrderDetailForOrderCenter
    from '@/lib/data-service/haolv-default/consumer_trains_order_getTrainsOrderDetailForOrderCenter'
import consumer_insurance_getEInsuranceOrderUrl
    from '@/lib/data-service/haolv-default/consumer_insurance_getEInsuranceOrderUrl'
import consumer_air_ticket_ApprovalMethod from '@/lib/data-service/haolv-default/consumer_air_ticket_ApprovalMethod'
import consumer_admin_trains_order_applyForRefundCost
    from '@/lib/data-service/haolv-default/consumer_admin_trains_order_applyForRefundCost'
import consumer_trains_order_applyForRefund from '@/lib/data-service/haolv-default/consumer_trains_order_applyForRefund'
import consumer_trains_order_applyForCancel from '@/lib/data-service/haolv-default/consumer_trains_order_applyForCancel' // 取消订单
import consumer_t_tp_refund_change_policy_get
    from '@/lib/data-service/haolv-default/consumer_t-tp-refund-change-policy_get' // 获取出差规则信息
import orderLog from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/order-log/1.0.0/index.vue';
import ApprovalProcess from "@/component/approval-process/1.0.0/index.vue"
import moment from 'moment'
import _ from 'underscore';
import TrainTimetable from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/train-timetable/1.0.0/index.vue'
import TrainOrderRulePopup from '@/page/admin/travel-book/train/component/orderRulePopup/1.0.0/index.vue'

export default {
    data() {
        return {
            trainInvoiceType: 0,
            // 动态表单内容
            reportConfigurationsBox: [],
            activeName: 'details',
            orderNo: '',
            type: 'detail',
            orderInfo: {
                passengers:[
                    {
                        boardingGates: ""
                    }
                ],
                trainstrainsOrder: []
            },
            orderInfoData: null,
            approvalMethod: null,
            passengerList: [],
            activePassengerItem: {},
            changePassengerShow: false,
            changeResult: [],
            refundPassengerShow: false,
            refundPassenger: {},
            reason: '',
            otherReason: '',
            reasonList: ['出差行程变化', '天气因素', '定错车次', '其他'],
            refuncAmount: {},

            orderRuleDialogVisible: false,
        }
    },
    components: {
        TrainOrderRulePopup,
        orderLog,
        ApprovalProcess,
        TrainTimetable
    },
    activated() {
        this.orderNo = this.$route.query.orderNo;
        if (this.$route.query.type) {
            this.type = this.$route.query.type
        } else {
            this.type = 'detail'
        }
        this.getDetail()
    },
    filters: {
        filterIntervalTime(val) {
            if (val) {
                let m = moment(val).format('mm分ss秒')
                return m
            } else {
                return '00分00秒'
            }
        },
        filterPassengerType(val) {
            // 1:成人 2：儿童 3：学生票 4：残军票
            if (val === 1) {
                return '成人'
            } else if (val === 2) {
                return '儿童'
            } else if (val === 3) {
                return '学生票'
            } else if (val === 4) {
                return '残军票'
            } else {
                return ''
            }
        },
        filterPassengerCardType(val) {
            // 1：身份证2：儿童无证件3：港澳通行证4：台 湾通行证5：护照
            if (val === '1') {
                return '身份证'
            } else if (val === '2') {
                return '儿童无证件'
            } else if (val === '3') {
                return '港澳通行证'
            } else if (val === '4') {
                return '台湾通行证'
            } else if (val === '5') {
                return '护照'
            } else {
                return ''
            }
        },
        filterOrderStatus(val) {
            // 1:-确认中 2- 待支付 3-待出票 4 -出票成功 5-出票失败 6-取消
            if (val === 1) {
                return '确认中'
            } else if (val === 2) {
                return '待支付'
            } else if (val === 3) {
                return '待出票'
            } else if (val === 4) {
                return '出票成功'
            } else if (val === 5) {
                return '出票失败'
            } else if (val === 6) {
                return '取消'
            } else {
                return ''
            }
        },
        filterGmtCreate(val) {
            return moment(val).format('YYYY-MM-DD HH:mm:ss')
        }
    },
    methods: {
        clickInsurance(val) {
            if (val.insuranceOrderId) {
                let data = {
                    insuranceOrderId: val.insuranceOrderId,
                };
                consumer_insurance_getEInsuranceOrderUrl(data).then(res => {
                    const __this = this;
                    const eleLink = document.createElement('a');
                    eleLink.href = res.datas.epolicyUrl;
                    eleLink.target = '_blank';
                    document.body.appendChild(eleLink);
                    eleLink.click();
                    document.body.removeChild(eleLink);
                })
            } else {
                this.$message.error('正在生成保单信息，请稍后查询...');
            }

        },
        // 订单日志
        getOrderLog() {
            this.$refs.orderLog.init({
                getDataSource: () => {
                    this.loading = true
                    // 调用获取订单日志的接口
                    // return consumer_log_hotelList({
                    //     orderNo: this.$route.query.id
                    // }).then(res => {
                    // 组装组件需要的list
                    let res = {
                        datas: [{
                            "operator": "个人测试",
                            "operatorTime": "2021-12-06T15:54:09",
                            "logSource": "1",
                            "logContents": null
                        }, {
                            "operator": "个人测试",
                            "operatorTime": "2021-12-06T15:55:01",
                            "logSource": "1",
                            "logContents": null
                        }, {
                            "operator": "个人测试",
                            "operatorTime": "2021-12-06T15:55:57",
                            "logSource": "1",
                            "logContents": "创建订单成功，订单号：13877698977300"
                        }, {
                            "operator": "个人测试",
                            "operatorTime": "2021-12-06T15:56:08",
                            "logSource": "1",
                            "logContents": "创建订单成功，订单号：13877698977300"
                        }, {
                            "operator": "个人测试",
                            "operatorTime": "2021-12-06T16:02:58",
                            "logSource": "1",
                            "logContents": "调用胤之旅接口，取消订单成功"
                        }]
                    }
                    const list = _.chain(res.datas)
                        .map(function (m) {
                            // 替换名字
                            const o = {
                                operator: m.operator,
                                date: m.operatorTime,
                                type: m.logSource,
                                detail: m.logContents
                            }
                            return o
                        }).value()
                    const d = {
                        entityList: list
                    }
                    return d || {}
                    // }).catch(() => {
                    //     return []
                    // }).finally(() => {
                    this.loading = false
                    // })
                }
            })
        },
        handleClick() {
            this.getOrderLog()
        },
        clickSpan() {
            if (this.$route.meta.data_for_back_layout && this.$route.meta.data_for_back_layout.layoutType === 'orderCenterType') {
                this.$router.push({
                    name: 'admin-order-center-train-order-list'
                })
            } else {
                this.$router.push({
                    name: 'admin-my-order-train'
                })
            }
        },
        getTime(gmtStart, gmtArrive) {
            let oldTime = new Date(gmtStart);
            let newTime = new Date(gmtArrive);

            let difftime = (newTime - oldTime) / 1000; //计算时间差,并把毫秒转换成秒

            let days = parseInt(difftime / 86400);
            let hours = parseInt(difftime / 3600) - 24 * days;
            let minutes = parseInt(difftime % 3600 / 60);

            if (days == 0) {
                return `${hours}时${minutes}分`
            } else {
                return `${days}天${hours}时${minutes}分`
            }

        },
        getWeek(data) {
            switch (data) {
                case 0:
                    return '星期日';
                case 1:
                    return '星期一';
                case 2:
                    return '星期二';
                case 3:
                    return '星期三';
                case 4:
                    return '星期四';
                case 5:
                    return '星期五';
                case 6:
                    return '星期六';
            }
        },
        goPay() {
            //因公因私区分，跳向不同支付页面，因公----admin-pay,因私----admin-travel-payment
            if (this.orderInfo.scheduledWay === 1) {
                this.$router.push({
                    name: "admin-travel-payment",
                    query: {
                        orderNo: this.orderInfo.orderNo,
                        orderType: "2"
                    }
                });
            } else {
                this.$router.push({
                    name: 'admin-pay',
                    query: {
                        orderNos: this.orderInfo.orderNo,
                        orderType: "2"
                    }
                });
            }
        },
        getOItem(linkNum, Status) {
            if (Status == 0) {
                return '未开始'
            } else if (Status == 1) {
                return '待审批'
            } else if (Status == 2) {
                return '通过'
            } else if (Status == 3) {
                return '拒绝'
            } else if (Status == 4) {
                return '已失效'
            }
        },
        getDetail() {
            const loading = this.$loading({
                lock: true,
                text: '',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let orderDetail = this.type === 'detail' ? consumer_trains_order_getTrainsOrderDetail : consumer_trains_order_getTrainsOrderDetailForOrderCenter
            orderDetail({orderNo: this.orderNo}).then(res => {
                loading.close();
                let orderInfo = res.datas;
                let passengerArr = [];
                let ticketPrice = 0;
                orderInfo.passengers.forEach(value => {
                    passengerArr.push(value.passengerName)
                    ticketPrice = value.ticketPrice
                });
                orderInfo.passengersList = passengerArr.join('/');
                orderInfo.ticketPrice = ticketPrice;
                orderInfo.reportConfigurations = orderInfo.reportConfigurations ? orderInfo.reportConfigurations : [];
                this.reportConfigurationsBox = [];
                if (orderInfo.reportConfigurations.length != 0) {
                    for (let i = 1;i <= Math.floor(orderInfo.reportConfigurations.length/3);i++) {
                        this.reportConfigurationsBox.push({
                            list: orderInfo.reportConfigurations.slice((i-1)*3,i*3)
                        });
                    }
                    if (orderInfo.reportConfigurations.length % 3 != 0) {
                        this.reportConfigurationsBox.push({
                            list: orderInfo.reportConfigurations.slice(-(orderInfo.reportConfigurations.length%3),orderInfo.reportConfigurations.length)
                        });
                    }
                }
                this.orderInfo = orderInfo;

                this.orderInfoData = [
                    {
                        buyerPaymentTotalAmount: this.orderInfo.buyerPaymentTotalAmount,
                        seatPrice: this.orderInfo.seatPrice,
                        serviceChargePrice: this.orderInfo.serviceChargePrice,
                        robTicketsFee: this.orderInfo.robTicketsFee,
                        passengers: this.orderInfo.passengers,
                    }
                ];

                this.$refs.approvalProcess.init({
                    status: orderInfo.status,
                    statusText: orderInfo.statusText,
                    approvalData: orderInfo.recordList,
                    approvalReason: orderInfo.approvalReason,
                    btnParams: {
                        isShow: false,
                        approvalParams: {},
                    }
                });

                if (orderInfo.applyNo) {
                    consumer_air_ticket_ApprovalMethod({applyNo: orderInfo.applyNo}).then(res => {
                        this.approvalMethod = res.datas.approvalMethod;
                    });
                }

                let passengerList = [];
                this.orderInfo.passengers.forEach((value, index) => {
                    if (value.showChangeButton === 1) {
                        passengerList.push(value)
                    }
                });
                this.passengerList = passengerList;

                this.orderInfo.milliseconds = this.orderInfo.milliseconds * 1000;
                if (this.orderInfo.milliseconds > 0) {
                    this.setPayInterval()
                }

                let params = {
                    fromStationCode: this.orderInfo.fromStationCode,
                    toStationCode: this.orderInfo.toStationCode,
                    trainCode: this.orderInfo.trainNo,
                    trainDate: this.$moment(this.orderInfo.startDate).format("YYYY-MM-DD"),
                };
                this.$refs.aTrainTimetable.init(params);

            }).catch(() => {
                loading.close()
            })
            consumer_t_tp_refund_change_policy_get().then(res => {
                this.trainInvoiceType = res.datas.trainInvoiceType ? res.datas.trainInvoiceType : 0;
            });
        },
        checkApplyNo() {
            if (this.approvalMethod == 1) {
                // 我的申请
                this.$router.push({
                    name: 'admin-my-apply-manage-apply-info',
                    query: {
                        applyId: this.orderInfo.applyId,
                        type: 'info',
                        approveType: this.orderInfo.approveType,
                        lastMenu: 'adminMyApply'
                    }
                })
            } else {
                // 待我审批
                this.$router.push({
                    name: 'admin-my-apply-manage-noApprove-detail',
                    query: {
                        applyId: this.orderInfo.applyId,
                        type: 'audit',
                        approveType: this.orderInfo.approveType,
                    }
                })
            }
        },
        checkEvection() {
            this.$router.push({
                name: 'admin-my-apply-manage-apply-info',
                query: {
                    applyId: this.orderInfo.applyId,
                    type: 'info',
                    approveType: this.orderInfo.approveType,
                    lastMenu: 'adminMyApply'
                }
            })
        },
        goChange(passenger) {
            if (passenger.showChangeButton === 2) {
                return;
            }
            // 判断是否只剩一个人
            if (this.passengerList.length === 1) {
                let userList = this.passengerList[0].userId;
                const query = {
                    evectionNo: this.orderInfo.evectionNo,
                    orderNo: this.orderInfo.orderNo,
                    fromStationCode: passenger.fromStationCode,
                    toStationCode: passenger.toStationCode,
                    evectionType: this.orderInfo.evectionType,
                    time: this.orderInfo.startDate,
                    userList: userList.toString()
                };
                this.$router.push({
                    name: 'admin-train-change-search',
                    query
                })
            } else {
                // 可选多人，弹窗选
                this.activePassengerItem = passenger
                this.changeResult = [passenger.userId];
                this.changePassengerShow = true;
            }
        },
        goRefund(passenger) {
            debugger
            if (passenger.showRefundButton === 2) {
                return;
            }
            this.refundPassenger = passenger;
            let params = {
                list: [passenger.sn],
                orderNo: this.orderNo
            }
            consumer_admin_trains_order_applyForRefundCost(params).then(res => {
                this.refuncAmount = {
                    refundAmount: res.datas.refundAmount,
                    refundCharge: res.datas.refundCharge,
                    ticketPrice: res.datas.ticketPrice
                }
                this.refundPassengerShow = true;
                this.$nextTick(() => {
                    let params = {
                        fromStationCode: this.orderInfo.fromStationCode,
                        toStationCode: this.orderInfo.toStationCode,
                        trainCode: this.orderInfo.trainNo,
                        trainDate: this.$moment(this.orderInfo.startDate).format("YYYY-MM-DD"),
                    };
                    this.$refs.bTrainTimetable.init(params);
                })
            })
        },
        isShowPassenger(val) {
            //判断订单状态未出票、待出票、出票中、出票完成、出票失败、退票完成、改签完成状态显示
            let status = [0, 1, 2, 3, 4, 7, 11];
            if (status.indexOf(val.ticketStatus) > -1) {
                //显示
                return true
            } else {
                //隐藏
                return false
            }
        },
        setStatus(val) {
            // 车票状态 0：待预定，1：待出票，2：出票中，3：出票完成，4：出票失败, 5：退票已申请, 6：退票处理中,
            // 7：退票完成， 8：退票失败，9：改签已申请，10：改签中， 11：改签完成，12：改签失败，
            // 13：改签已取消， 14：已改签， 15：待支付， 16：预定中， 17：占座失败
            let result = '';
            if ([3, 7, 11].indexOf(val) >= 0) {
                result = 'success'//绿色
            } else if ([0, 13].indexOf(val) >= 0) {
                result = 'cancel'//灰色
            }else if ([4, 8, 12, 17].indexOf(val) >= 0) {
                result = 'fail'//红色
            } else {
                result = 'pending'//黄色
            }
            return result
        },
        submitChange() {
            if (this.changeResult.length === 0) {
                this.$message({
                    type: 'warning',
                    message: '请选择改签出行人'
                });
                return
            }
            this.changePassengerShow = false;
            let userList = this.changeResult.join(',');
            const query = {
                evectionNo: this.orderInfo.evectionNo,
                orderNo: this.activePassengerItem.orderNo,
                fromStationCode: this.activePassengerItem.fromStationCode,
                toStationCode: this.activePassengerItem.toStationCode,
                evectionType: this.orderInfo.evectionType,
                time: this.orderInfo.startDate,
                userList: userList
            };
            this.$router.push({
                name: 'admin-train-change-search',
                query
            })
        },
        // 确定退票
        submitRefund() {
            if (this.reason === '') {
                this.$message({
                    type: 'warning',
                    message: '请勾选退票原因'
                });
                return;
            }

            if (this.reason === '其他' && this.otherReason.trim() === '') {
                this.$message({
                    type: 'warning',
                    message: '请填写退票原因'
                });
                return;
            }

            let _params = {
                orderNo: this.orderNo,
                passengerSn: this.refundPassenger.sn,
                reason: this.reason === '其他' ? this.otherReason : this.reason,
            };

            const loading = this.$loading({
                lock: true,
                text: '',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });


            consumer_trains_order_applyForRefund(_params).then((res) => {
                loading.close()
                this.refundPassengerShow = false;
                this.$router.push({
                    name: 'admin-my-order-train-refund-detail',
                    query: {
                        refundNo: res.refundNo,
                    }
                })
            }).catch(err => {
                this.refundPassengerShow = false;
                loading.close()
            });
        },
        handleCloseChange() {
            this.changeResult = [];
            this.changePassengerShow = false;
        },
        handleCloseRefund() {
            this.refundPassengerShow = false;
        },
        //去支付
        toPay() {
            if (this.orderInfo.scheduledWay === 1) {
                this.$router.push({
                    name: 'admin-personal-train-submit-order',
                    query: {
                        orderNo: this.orderNo
                    }
                })
            } else {
                this.$router.push({
                    name: 'admin-pay',
                    query: {
                        orderType: '2',
                        orderNos: this.orderNo
                    }
                })
            }
        },
        //取消订单
        toCancelOrder() {
            this.$confirm('一天仅有3次取消订单的机会(包括自动取消)，超过3次后当日将无法使用12306账号预订', '确认要取消订单吗', {
                type: 'warning',
                confirmButtonText: '取消订单',
                cancelButtonText: '稍后再说',
                callback: action => {
                    if (action == 'confirm') {
                        const loading = this.$loading({
                            lock: true,
                            text: '',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                        consumer_trains_order_applyForCancel({orderNo: this.orderNo}).then(res => {
                            loading.close();
                            this.$alert('你已成功取消预订', '取消预订提示', {
                                confirmButtonText: '知道了',
                                callback: action => {
                                    this.getDetail();
                                }
                            })
                        }).catch(() => {
                            loading.close();
                            this.$message.error('订单取消失败');
                        })
                    }
                }
            });
        },
        //剩余时间倒计时
        setPayInterval() {
            this.intervalFun = setInterval(() => {
                this.orderInfo.milliseconds = this.orderInfo.milliseconds - 1000
                if (this.orderInfo.milliseconds <= 0) {
                    clearInterval(this.intervalFun)
                    this.getDetail()
                }
            }, 1000)
        },
        back() {
            this.$router.back();
        },
        showRulePopup() {
            this.orderRuleDialogVisible = true;
        },
    }
}
